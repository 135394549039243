import React, {useContext} from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {GlobalStateContext} from '../context/GlobalContextProvider'

function Experience({location, data}){
    const siteTitle = data.site.siteMetadata.title
    const state = useContext(GlobalStateContext)
    const {experience: {title, subtitleEducation, education, subtitleProfessional, companies}} = state.language

    return(
        <Layout location={location} title={siteTitle}>
            <SEO title="Contato" />
            <section className="section-experience">
                <div className="wrapper medium">
                    <h1>{title}</h1>
                    <section className="page-content">
                        <h2>{subtitleEducation}</h2>
                        <ul>
                            {education.map((el, index)=>(
                                <li key={index}>{el}</li>
                            ))}
                        </ul>
                    </section>
                    <section className="page-content">
                        <h2>{subtitleProfessional}</h2>
                        {companies.map(({title, text, list})=>(
                            <div className="page-content">
                                <h3>{title}</h3>
                                <p>{text}</p>
                                {list.map((el, index)=>(
                                    <p key={index}>{el}</p>
                                ))}
                                {/* <ul>
                                    {list.map((el, index)=>(
                                        <li key={index}>{el}</li>
                                    ))}
                                </ul> */}
                            </div>
                        ))}
                    </section>
                </div>
            </section>
        </Layout>
    )
}

export default Experience

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`